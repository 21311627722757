import {useEffect} from 'react';
import axios from 'axios';
import Navbar from './Navbar';
import { globalContext } from './GlobalContext';
import { useContext } from 'react';
import Popup from './Popup';
import Post from './Post';
import Comments from './Comments';
import './Home.css'

function Home() {
    let {globalState, setGlobalState} = useContext(globalContext);

    useEffect(() =>{
        axios.get('https://my-app.njwang.workers.dev/post')
            .then(res => {
                setGlobalState(prevState => {
                    return {
                        ...prevState,
                        posts: res.data
                    }
                })
            })
    }, [])

    useEffect(() => {
        globalState.showPopup || globalState.showComments ? document.body.style.overflow = 'hidden' : document.body.style.overflow = 'auto'
    }, [globalState.showPopup, globalState.showComments]);

    function renderPosts() { 
        let posts = globalState.posts.map(post => 
            <Post key={post.id} id={post.id} title={post.title} content={post.content} username={post.username} rating={post.rating} imageLink={post.imageLink}/>
        );
        return posts;
    }

    return(
        <div>
            {globalState.showPopup ? <Popup/> : null}
            {globalState.showPopup || globalState.showComments ? <div className='darken'></div> : null}
            {globalState.showComments != null ? <Comments/>:null}
            <Navbar/>
            <div className='header'>Let's <span style={{fontSize: "52px", color:"rgba(235, 154, 16, 0.8)"}}>Share</span></div>
            <div className='sub-header'>Check out a post, or make your own.</div>
            <div className='posts'>
                {renderPosts()}
            </div>
        </div>
    )
}

export default Home;