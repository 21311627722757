import './Home.css';
import {useState, useContext} from 'react';
import axios from 'axios';
import { globalContext } from './GlobalContext';
function Post(props) {
    let [id] = useState(props.id);
    let [title] = useState(props.title);
    let [content] = useState(props.content);
    let [username] = useState(props.username);
    let [upvoted, setUpvoted] = useState(false);
    let [rating, setRating] = useState(props.rating);
    let [imageLink] = useState(props.imageLink);
    let {setGlobalState} = useContext(globalContext);

    function upvote() {
        if(!upvoted) {
            setRating(rating+1);
            setUpvoted(true);
        }
        else {
            setRating(rating-1);
            setUpvoted(false);
        }

        axios.post('https://my-app.njwang.workers.dev/upvote', {
            username: username,
            id: id,
            title: title,
            content: content,
            imageLink: imageLink,
            rating: upvoted ? rating-1 : rating+1
        })
    }

    function showComments(id) {
        setGlobalState(prevState => {
            return {
                ...prevState,
                showComments: {
                    id: id,
                    title: title,
                    content: content,
                    username: username,
                    imageLink: imageLink,
                    rating: rating
                }
            }
        })
    }

    return(
        <div key={id} className='post'>
            <div style={{fontWeight:"bold", fontSize:"22px", borderBottom:"0.5px solid rgba(0,0,0,0.2)",paddingBottom:"0.5em"}}>{title}</div>
            <div style={{position: 'relative', marginTop:"1em", marginBottom:"2em", overflowY:'auto', maxHeight:'250px'}}>
                <div>{content}</div>
                {imageLink.length > 0 ? <img style={{objectFit:"scale-down", maxWidth:"100%", maxHeight:"40%", marginTop:"0.5em"}} src={imageLink} alt='user submitted link'/> : null}
            </div>
            
            <div className='post-bottom' style={{position:"absolute", width: '90%', marginTop:"1em", bottom: "1em", display:'flex', justifyContent:'space-between'}}  >
                <div style={{fontStyle:"italic"}}>by {username}</div>
                <div>
                    <span style={{marginRight:'5px'}}>{rating}</span><i onClick={() => {upvote()}} style={{marginRight:'15px', color: `${upvoted ? 'lightgreen' : 'black'}`}} className="fas fa-arrow-up"></i>
                    <i className="far fa-comment" onClick={()=> {showComments(id)}}></i>
                </div>
            </div>
        </div>
    )
}

export default Post;