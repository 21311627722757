import axios from "axios";
import { useState, useEffect, useRef, useContext } from "react";
import Post from "./Post";
import { globalContext } from "./GlobalContext";

function Comments(props) {
    const [comments, setComments] = useState([]);
    const [commentText, setCommentText] = useState('');
    const [username, setUsername] = useState('');
    const wrapRef = useRef(null);
    const {globalState, setGlobalState} = useContext(globalContext);
    const post = globalState.showComments;
    useEffect(()=> {
        function clickOutside(e) {
            if(wrapRef.current && !wrapRef.current.contains(e.target)) {
                setGlobalState(prevState => {
                    return {
                        ...prevState,
                        showComments: null
                    }
                })
            }
        }
        document.addEventListener('mousedown', clickOutside);

        axios.get('https://my-app.njwang.workers.dev/comments', {
            params: {
                id: globalState.showComments.id
            }
        }).then(res => {
            setComments(res.data);
        })
    }, [])

    function updateComment(e) {
        setCommentText(e.target.value)
    }
    function updateUsername(e){
        setUsername(e.target.value)
    }
    function handleSubmit(e) {
        e.preventDefault();
        let newComment = {
            post_id: post.id,
            username: username,
            comment: commentText,
            comment_id: comments.length+1
        }
        axios.post('https://my-app.njwang.workers.dev/comments', newComment)
            .then(res => {
                setComments(prevComments => {
                    return [...prevComments, newComment]
                })
            })
    }
    return(
        <div ref={wrapRef} className='comments'>
            <Post key={post.id} id={post.id} title={post.title} content={post.content} username={post.username} rating={post.rating} imageLink={post.imageLink}/>
            <div className='vertical-line'></div>
            {comments.length > 0 ? (
                <div className='comments-right'>
                    <div>
                        <div style={{textAlign:'left', fontWeight:'bold', fontSize:'24px',borderBottom:'1px solid rgba(0,0,0,0.2)', marginBottom:'0.5em', paddingBottom:'0.5em'}}>Comments</div>
                        <div className='comments-section' style={{overflow:'auto', maxHeight:'200px'}}>{comments.map(comment => <div><div style={{fontWeight:'bold', fontStyle:'italic'}}>{comment.username}</div><div>{comment.comment}</div></div>)}</div>
                    </div>
                    <div style={{position:'relative',width:'100%'}}>
                        <form onSubmit={handleSubmit}> 
                            <input onChange={updateUsername} style={{display:'block', width:'100%', marginTop:'0.5em', marginBottom:'0.5em', boxSizing:'border-box'}} type='text' placeholder='Username'></input>
                            <textarea onChange={updateComment} style={{display: 'block', width:'100%', boxSizing:'border-box'}} placeholder='Leave a comment...'></textarea>
                            <button type='submit' style={{marginTop:'0.5em', backgroundColor:'rgba(235, 154, 16, 0.8)', color:'white', fontWeight:'bold', border:'none',padding:'0.5em', borderRadius:'10px'}}>Comment</button>
                        </form>
                    </div>
                </div>)
                : (
                <div className='comments-right'>
                    <div className='comments-right-top'>
                        <div style={{textAlign:'left', fontWeight:'bold', fontSize:'24px',borderBottom:'1px solid rgba(0,0,0,0.2)', marginBottom:'0.5em', paddingBottom:'0.5em'}}>Comments</div>
                        <div style={{fontStyle:'italic'}}>No comments yet!</div>
                    </div>
                    <div style={{position:'relative',width:'100%'}}>
                        <form onSubmit={handleSubmit}> 
                            <input onChange={updateUsername} style={{display:'block', width:'100%', marginTop:'0.5em', marginBottom:'0.5em', boxSizing:'border-box'}} type='text' placeholder='Username'></input>
                            <textarea onChange={updateComment} style={{display: 'block', width:'100%', boxSizing:'border-box'}} placeholder='Leave a comment...'></textarea>
                            <button type='submit' style={{marginTop:'0.5em', backgroundColor:'rgba(235, 154, 16, 0.8)', color:'white', fontWeight:'bold', border:'none',padding:'0.5em', borderRadius:'10px'}}>Comment</button>
                        </form>
                    </div>
                </div>
                )}
        </div>
    )
}
export default Comments;