import './App.css';
import {BrowserRouter, Switch, Route} from 'react-router-dom';
import GlobalContextProvider from './GlobalContext';
import Home from './Home.js'

function App() {
  return (
    <div className="App">
      <GlobalContextProvider>
      <BrowserRouter>
        <Switch>
          <Route exact path='/' component={Home}></Route>
        </Switch>
      </BrowserRouter>
      </GlobalContextProvider>
    </div>
  );
}

export default App;
