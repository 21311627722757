import React, {useState} from 'react';

let initialState = {
    showPopup: false,
    posts: [],
    showComments: null
}

export const globalContext = React.createContext(initialState);

function GlobalContextProvider(props) {
    const [globalState, setGlobalState] = useState(initialState);
    return <globalContext.Provider value={{globalState, setGlobalState}}>
        {props.children}
    </globalContext.Provider>
}

export default GlobalContextProvider;