import axios from 'axios';
import { useState, useEffect, useRef, useContext } from 'react';
import { globalContext } from './GlobalContext';
import './Home.css';
function Popup() {
    const wrapRef = useRef(null);
    const {setGlobalState} = useContext(globalContext);
    const [error, setError] = useState(null);
    const [title, setTitle] = useState('');
    const [username, setUsername] = useState('');
    const [content, setContent] = useState('');
    const [image, setImage] = useState('');
    useEffect(() => {
        function clickOutside(e) {
            if(wrapRef.current && !wrapRef.current.contains(e.target)) {
                setGlobalState(prevState => {
                    return {
                        ...prevState,
                        showPopup: false
                    }
                })
            }
        }
        document.addEventListener('mousedown', clickOutside)
    }, [])
    
    function updateTitle(e) {
        setTitle(e.target.value);
    }
    function updateUsername(e) {
        setUsername(e.target.value);
    }
    function updateContent(e) {
        setContent(e.target.value)
    }
    function updateImage(e) {
        setImage(e.target.value);
    }
    function handleSubmit(e) {
        e.preventDefault();
        if(username.length == 0 || title.length == 0 || content.length == 0) {
            setError('Invalid/empty fields');
        }
        else {
            let newPost = {
                username: username,
                title: title,
                content: content,
                imageLink: image
            }
            axios.post('https://my-app.njwang.workers.dev/post', newPost)
                .then(res => {
                    setError(null);
                    setGlobalState(prevState => {
                        return {
                            ...prevState,
                            showPopup: false,
                            posts: [
                                ...prevState.posts,
                                {
                                    ...newPost,
                                    id: prevState.posts.length,
                                    rating: 0
                                }
                            ]
                        }
                    })
                })
        }
    }

    return ( 
        <div ref={wrapRef} className='popup'>
            <div style={{fontSize:'28px', fontWeight:'bold', marginTop: '1em'}}>Create a new post</div>
            <form onSubmit={handleSubmit}>
                <input onChange={updateTitle} type='text' placeholder='Title' className='new-title'/>
                <input onChange={updateUsername} type='text' placeholder='Username' className='new-username'/>
                <input onChange={updateImage} type='text' placeholder='Image link' className='new-image'/>
                <textarea onChange={updateContent} className='new-content' rows='5' placeholder='Type your post here...'></textarea>
                {error ? <div style={{backgroundColor: 'rgba(246, 118, 91)', width: '75%', marginLeft: '12.25%', borderRadius: '10px'}}>{error}</div> : null}
                <button type='submit' style={{display:'block', border: 'none', borderRadius:'10px', color:'white', fontSize:'18px',fontWeight:'bold', width: '15%', height: '3em', marginLeft:"42.5%", marginTop:'1em', marginBottom:'1em', backgroundColor: 'rgba(235, 154, 16, 0.8)'}}>Post</button>
            </form>
        </div>
    )
}

export default Popup;