import './Navbar.css'
import { globalContext } from './GlobalContext';
import cloud from './cloud.png'
import { useContext } from 'react';

function Navbar() {
    const {setGlobalState} = useContext(globalContext);
    function enablePopup() {
        setGlobalState(prevState => {
            return {
                ...prevState,
                showPopup: true
            }
        });
    }
    return <div className='navbar'>
        <div className='logo'><img src={cloud} className='cloud' alt='cloud-logo'/><div>SocialCloud</div></div>
        <div className='new-post' onClick={enablePopup}>New Post</div>
    </div>
}

export default Navbar;